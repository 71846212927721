
.videoContainer {
    position: relative;
    width: 100%;
    display: block;
}

.video {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    border: none;
    display: block;
    width: 100%;
    object-fit: cover; 
}

.controls {
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.controlIcons {
    fill: rgba(255, 255, 255, 0.5);
    height: 20px;
    width: 20px;
    cursor: pointer;
    padding: 10px;
}

.controlSubContainer {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.controlIconContainerLeftRight {
    display: flex;
}

.controlIconsWhite {
    fill: white;
}

.controlIcons:hover {
    fill: white;
    height: 45px;
    width: 45px;
}

.videoContainer:hover .controls {
    display: flex;
}

.controlIconContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.progressBarContainer {
    margin: 10px;
    display: flex;
    justify-content: start;
    position: relative;
    width: 90%;
}

.progressBarCurrentPosition {
    background-color: white;
    height: 2px;
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
}

.progressBarTotalDuration {
    background-color: rgba(255, 255, 255, 0.5);
    height: 2px;
    width: 100%;
    cursor: pointer;
    position: relative;
}

.progressBarItem {
    height: 5px;
    cursor: pointer;
}

.progressBarItemStrength {
    background-color: rgba(0, 255, 0, 0.4);
}

.progressBarItemStrength:hover {
    background-color: green;
}

.progressBarItemWeakness {
    background-color: rgba(255, 0, 0, 0.4);
}

.progressBarItemWeakness:hover {
    background-color: red;
}

.progressBarItemHighlight {
    background-color: rgba(255, 255, 0, 0.4);
}

.progressBarItemHighlight:hover {
    background-color: yellow;
}

.progressBarKnot {
    height: 10px;
    width: 10px;
    background-color: white;
    position: absolute;
    top: -5px;
    border-radius: 5px;
}
