.preCallContainer {
    width: 100%;
}

.contentCardDisplay {
    width: 100%;
}

.contentCardsContainer {
    display: flex;
}