.emailDraftContainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.emailDraft {
    width: 100%;
    height: 700px;
    margin-bottom: 20px;
    padding: 10px;
    font-size: 14px;
    line-height: 1.5;
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 20px;
    font-family: Arial, Helvetica, sans-serif;
}