
.contentCardDisplayHeader {
    background-color: white;
    margin: 10px;
    padding: 25px;
    border-radius: 25px;
}

.participantAvatar {
    width: 60px;
    height: 60px;
    border-radius: 40px;
    padding: 10px;
}

.icon {
    width: 20px;
    height: 20px;
    padding-right: 10px;
}

.dateDetails {
    padding-right: 10px;
}

.companyName {
    font-size: 24px;
    font-weight: 500;
}

.industrySegment {
    display: flex;
}

.personaContainer {
    display: flex;
}

.callDetails {
    display: flex;
    padding-bottom: 15px;
}
.participants {
    display: flex;
    padding-top: 10px;
}
.participant {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F5F6FB;
    padding: 2px;
    flex: 0;
    border-radius: 20px;
    margin: 5px;
}

.participantName {
    font-weight: 600;
    width: 200px;
}

.participantTitle {
    font-weight: 100;
    width: 100%;
}

.participantDetail {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.overallDetails {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1;
}