.icon {
    fill: black;
    height: 30px;
    width: 30px;
    cursor: pointer;
    padding: 10px;
}

.timestampSelectionContainer {
    background-color: white;
    flex-direction: column;
    border-radius: 20px;
    display: flex;
    justify-content: start;
    margin-left: 20px;
    margin-bottom: 20px;
}

.timestampSelectionHeader {
    display: flex;
    flex: 1;
}

.timestampSelectionHeaderItem {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    width: 155px;
    border-bottom: 1px solid #F5F6FB;
}

.timestampSelectionHeaderItem:hover {
    background-color: #E4EBFF;
}

.timestampSelectionHeaderItemActive {
    background-color: #FFF2D0;
}

.timestampSelectionHeaderItemActive:hover {
    background-color: #FFF2D0;
}

.timestampSelectionContent {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.timestampSelectionContentItem {
    display: flex;
    padding: 10px;
    border-bottom: 1px solid #F5F6FB;; /* Adjust thickness and color as needed */
    flex: 1;
    cursor: pointer;
}

.timestampSelectionContentItem:hover {
    background-color: #E4EBFF;
}

.timestampSelectionContentItemHighlighted {
    background-color: #FFF2D0;
}

.improvementIcon {
    fill: red;
    width: 20px;
    height: 20px;
    padding-right: 5px;
}

.strengthIcon {
    fill: green;
    width: 20px;
    height: 20px;
    padding-right: 5px;
}