.contentCardTypeHeader {
    display: flex;
    align-items: flex-start;
}

.contentCardIcon {
    height: 20px;
    width: 20px;
    padding-right: 5px;
    padding-left: 20px;
}

.contentCardModalName {
    font-size: 15px;
    font-weight: 300;
    padding-right: 10px;
}

.contentCardModalHeader {
    background-color: white;
    border-radius: 20px;
    padding-bottom: 20px;
}

.contentCardName {
    font-size: 22px;
    font-weight: 200;
    padding: 20px;
    padding-bottom: 3px;
}

.contentCardChampionsDescription {
    padding: 10px;
    padding-left: 20px;
}

.contentCardTitleContainer{
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.closeIcon {
    height: 22px;
    width: 22px;
    padding: 20px;
    padding-bottom: 0px;
    cursor: pointer;
}

.handlingExampleContainer {
    background-color: white;
    max-width: 300px;
    padding: 20px;
    margin: 20px;
    border-radius: 20px;
}

.exampleContainer {
    display: flex;
}

.handlingExampleTitle {
    padding: 10px;
    font-weight: 400;
    padding-bottom: 0px;
}

.handlingExampleTalkTrack {
    padding: 10px;
    font-weight: 200;
}