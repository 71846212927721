.contentCardSectionHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Clash Display', sans-serif;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}

.contentCardSectionName {
    display: flex;
    align-items: center;
    font-size: 15px;
}

.contentCardSection {
    background-color: white;
    padding: 20px;
    border-radius: 32px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    flex: 1;
    opacity: 60%;
}

.contentCardSectionActive {
    cursor: pointer;
    opacity: 100%;
}

.contentCardSectionSelected {
    background-color: #FFF2D0;
    cursor: pointer;
    opacity: 100%;
}

.contentCardSectionActive:hover {
    background-color: #E4EBFF;
}

.contentCardHeaderContainer {
    flex: 1;
    padding-bottom: 10px;
}

.contentCardsCollectionContainer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contentCardsCollection {
    display: flex;
    flex-wrap: wrap;
    gap: 2px;
    width: 125px;
    height: 31px;
}

.contentCardSectionIcon {
    width: 30px;
    height: 30px;
    padding: 15px; 
}

.contentCardIcon {
    width: 22px;
    height: 30px;
    fill: lightgrey;
}

.contentCardIconActive {
    fill: blue;
}

.contentcardSection {
    padding-top: 20px;
    padding-bottom: 20px;
}

.contentcardSectionTitle {
    font-size: 22px;
    padding: 20px;
    font-weight: 300;
}

.contentCards {
    display: flex;
}