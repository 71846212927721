.videoPlayerContainer {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex: 1;
}

.videoFeedback {
    display: flex;
    flex-direction: row;
}

.improvementSuggestion {
    display: flex;
    flex: 1;
    margin-top: 10px;
}

.improvementSuggestionInactive {
    opacity: 0.6;
}

.leftSideBar {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 370px;
    flex-grow: 1;
    flex-basis: 0%;
}
