.container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.selector {
    display: flex;
}

.selectorItem {
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    margin: 5px;
    cursor: pointer;
}

.selectorItem:hover {
    background-color: #E4EBFF;
}

.selectorItemSelected {
    background-color: #FFF2D0;
}