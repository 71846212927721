.appContent {
    padding-left: 40px;
    display: flex;
    width: 100%;
    flex: 1;
    overflow-y: hidden;
    box-sizing: border-box;
}

.videoSelector {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: start;
    overflow-y: scroll;
    height: 100%;
    padding: 40px;
}

.gridContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 40px;
}

.navbarContainer {
    display: flex;
    position: absolute;
    height: 100%;
    left: 0px;
    top: 0px;
    flex-direction: column;
    background-color: white;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    justify-content: space-between;
    width: 40px;
    transition: width 0.2s ease;  /* 1-second transition */
    overflow-x: hidden;
    z-index: 10;
}

.navbarContainerItem {
    font-family: 'Clash Display', sans-serif;
    font-size: 16px;
    font-weight: 500;
    /* background-color: #F5F6FB; */
    border-radius: 15px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    transition: width 0.2s ease;  /* 1-second transition */
    min-width: max-content;
}

.navbarContainerItem:hover {
    background-color: #E4EBFF;
}

.navbarContainerHovered {
    width: 200px;
}

.navbarIcon {
    width: 20px;
    height: 20px;
}

.navbarContainerItemTitle {
    padding-left: 10px;
}

.videMeetingItemIcon {
    width: 26px;
    height: 26px;
    border-radius: 13px;
    margin-right: 10px;
}