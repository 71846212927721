.videMeetingItem {
    display: flex;
    flex-direction: column;
    background-color: white;
    margin: 1px;
    padding: 20px;
    border-radius: 20px;
    cursor: pointer;
    border: 2px solid white;
    margin: 5px;
    width: 300px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
}

.videMeetingItem:hover {
    background-color: #E4EBFF;
    border: 2px solid #E4EBFF;
}
.videMeetingItemActive {
    background-color: #FFF2D0;
    border: 2px solid #FFF2D0;
}

.videMeetingItemSelected {
    border: 2px solid black;
}

.videMeetingItemTitle {
    display: flex;
    margin: 4px;
    font-size: 20px;
    font-family: 'Clash Display', sans-serif;
}

.videMeetingItemDate {
    display: flex;
    margin: 4px;
}

.videMeetingItemDescription {
    display: flex;
    margin: 4px;
    font-weight: 100;
    font-size: 14px;
}

.videMeetingParticipants {
    display: flex;
    flex-direction: column;
    margin: 10px;
    font-weight: 300;
}

.videMeetingItemIcon {
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-right: 10px;
}

.videMeetingItemHeader {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 10px;

}

.videMeetingDateTimeIcon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.videMeetingItemDateTimeContainer {
    display: flex;
    align-items: center;
    justify-content: start;
}