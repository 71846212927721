.container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.salesforceUpdateContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 20px;
    margin: 10px;
    height: 250px;
    border-left: 5px;
    width: 450px;
}

.salesforceUpdateContainerCompleted {
    border-left: 5px solid green;
}

.salesforceUpdateContainerMissing {
    border-left: 5px solid red;
}

.salesforceUpdateContainerPending {
    border-left: 5px solid #ffd664;
}

.salesforceUpdateTextContainer {
    display: flex;
    margin: 10px;
}

.salesforceUpdateTextContainerItem {
    display: flex;
    flex-direction: column;
    align-items: start;
    flex: 1;
}

.salesforceUpdateContainerHeader {
    display: flex;
    justify-content: space-between;
    margin: 10px;
}

.salesforceUpdateContainerHeaderControls {
    display: flex;
}

.salesforceUpdateContainerHeaderControlsAccept {
    padding: 5px;
    border: 0.5px solid green;
    border-radius: 5px;
    color: green;
    cursor: pointer;
    font-weight: 200;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.salesforceUpdateContainerHeaderControlsReject {
    padding: 5px;
    border: 0.5px solid red;
    border-radius: 5px;
    color: red;
    cursor: pointer;
    margin-right: 10px;
    font-weight: 200;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.salesforceUpdateContainerHeaderControlsCompleted {
    padding: 5px;
    border-radius: 5px;
    background-color: #F0FDF4;
    color: green;
}

.salesforceUpdateContainerHeaderControlsMissing {
    padding: 5px;
    border-radius: 5px;
    background-color: #FFE8E8;
}

.salesforceUpdateTextContainerPrevious {
    background-color: #FDF4F4;
    color: red;
    padding: 5px;
    border-radius: 5px;
}

.salesforceUpdateTextContainerItemHeader {
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px;
    font-weight: 200;
    font-size: 12px;
    margin: 5px;
}

.salesforceUpdateTextContainerItemHeaderSuggested {
    background-color: #F0FDF4;
    color: green;
}

.salesforceUpdateTextContainerItemHeaderPrevious {
    background-color: #FDF4F4;
    color: red;
}

.salesforceUpdateTextContainerItemHeaderCurrent {
    background-color: #F4F4FD;
    color: blue;
}

.salesforceUpdateTextContainerItemContent {
    padding: 20px;
    margin-top: 5px;
    margin: 5px;
    border-radius: 5px;
    font-size: 12px;
}

.salesforceUpdateTextContainerItemContentSuggested {
    background-color: #F0FDF4;
}

.salesforceUpdateTextContainerItemContentPrevious {
    background-color: #FDF4F4;
}

.salesforceUpdateTextContainerItemContentCurrent {
    background-color: #F4F4FD;
}

.icon {
    height: 10px;
    width: 10px;
}

.iconPlus {
    fill: green;
}

.iconMinus {
    fill: red;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    padding-right: 5px;
}

.MEDDPICCHeader {
    display: flex;
    align-items: center;
}

.statusIcon {
    width: 20px;
    height: 20px; 
    padding-left: 8px;
}

.completeIcon {
    fill: green;
}

.missingIcon {
    fill: red;
}

.decisionIcon {
    fill: #ffd664;
}

.undoIcon {
    width: 15px;
    height: 15px; 
}

.undoIconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    cursor: pointer;
}