.championAvatar {
    width: 40px;
    height: 40px;
    border-radius: 40px;
}

.placeIcon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0px;
    bottom: 0px;
}

.championAvatarContainer {
    position: relative;
    width: 40px;
    height: 40px;
    padding: 10px;
}

.championContainer {
    /* position: relative; */
    display: flex;
    background-color: #F5F6FB;
    border-radius: 20px;
    width: 250px;
    padding: 5px;
    margin: 5px;
}

.championName {
    font-size: 15px;
    font-weight: 400;
}

.championNameContainer {
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    padding-left: 10px;
    flex: 1;
}

.champions {
    display: flex;
    padding-left: 10px;
}

.rank {
    font-weight: 200;
    padding-bottom: 2px;
    flex: 1;
}

.winRate {
    /* position: absolute; */
    /* top: -15px; */
    background: #E8F5E9;
    font-size: 12px;
    margin: 4px;
    padding: 4px;
    border-radius: 3px;
    /* right: -15px; */
}

.rankWinRate {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
}