.contentCardName {
    font-size: 22px;
    font-weight: 200;
    padding: 20px;
    width: 100%;
}

.contentCard {
    display: flex;
    flex-direction: column;
    width: 240px;
    height: 330px;
    background-color: white;
    border-radius: 20px;
    margin: 10px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    position: relative;
    border: 1px solid white;
    padding: 10px;
}

.contentCardActive {
    border: 1px solid #E0ECFF;
    background-color: #E0ECFF;
}

.contentCardInActive {
    border: 1px solid white;
}

.contentCardUl {
    list-style-type: circle;
    padding-left: 30px;
    margin: 0px;
    font-weight: 200;
    font-size: 12px;
}

.contentCardTalkTrack {
    padding: 15px;
    font-weight: 100;
    font-size: 12px;
    flex: 1;
}

.contentCardIcons {
    position: absolute;
    bottom: -33px;
    left: 10px;
    height: 20px;
    padding: 6px;
    display: none;
    justify-content: space-between;
    width: 180px;
    fill: black;
}

.lastSeenContainer {
    display: flex;
    font-weight: 100;
    font-size: 12px;
    justify-content: center;
    align-items: center;
}

.contentCardIcon {
    width: 20px;
    height: 20px;
    padding-right: 5px;
}

.contentCard:hover .contentCardIcons {
    display: flex;
}

.header{
    display: flex;
    justify-content: center;
}

.checkMarkIcon {
    width: 30px;
    height: 30px;
    padding-top: 15px;
    padding-right: 15px;
    fill: blue;
}

.detailsContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 98%;
}

.details {
    padding: 5px;
    border: 1px solid lightgrey;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    font-size: 13px;
    border-radius: 5px;
    margin: 10px;
}