.taylorImprovementSuggestionContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #FFE8E8;
    flex: 1;
    margin: 10px;
    border-radius: 20px;
}

.taylorImprovementSuggestionStrength {
    background-color: #E8F5E9;
}

.taylorImprovementSuggestionHeader {
    display: flex;
    flex-direction: column;
}

.taylorImprovementSuggestionContentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.taylorImprovementSuggestionContent {
    max-width: 300px;
    font-weight: 200;
    padding-bottom: 30px;
}

.taylorImprovementSuggestionKindHeader {
    display: flex;
    margin-top: 10px;
    margin-right: 10px;
    justify-content: end;
}

.improvementIcon {
    height: 20px;
    width: 20px;
    padding-left: 5px;
}

.contentCardSectionHeader {
    display: flex;
    align-items: center;
    padding: 10px;
    padding-bottom: 0px;
}

.contentCardIcon {
    height: 40px;
    width: 40px;
    padding: 10px;
    padding-bottom: 0px;
}

.contentCardSectionName {
    font-size: 20px;
}
