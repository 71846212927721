.modal {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Background color with opacity */
}

.modalContent {
    background-color: #F5F6FB;
    cursor: auto;
    margin: 5% auto;
    width: 80%;
    max-width: 1000px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}