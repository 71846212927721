.callPageContent {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 100%;
    overflow-y: auto;
}

.startCallButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: aqua;
    padding: 15px;
    border-radius: 10px;
}

.startCallButtonIcon {
    margin: 10px;
}

.nagivationContent {
    display: flex;
}

.contentcardSectionTitle {
    font-size: 22px;
    padding: 20px;
    font-weight: 300;
}

.topNavContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 15px;
}

.preCallItem {
    height: 48px;
    display: flex;
    width: 120px;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
    cursor: pointer;
}

.postCallItem {
    height: 48px;
    display: flex;
    width: 155px;
    align-items: center;
    justify-content: center;
    border-radius: 28px;
}

.prePostContainer {
    display: flex;
    background-color: white;
    width: 500px;
    height: 56px;
    border-radius: 28px;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.backItem {
    display: flex;
    background-color: white;
    height: 56px;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    width: 160px;
    border-radius: 28px;
    cursor: pointer;
}

.prePostActive {
    color: white;
    background-color: #1C1C21;
}

.precallpageContainer {
    width: 80%;
    max-width: 1200px;
}

.topSellerExample {
    display: flex;
    margin-top: 20px;
}
