.transcriptContainer {
    display: flex;
    flex-direction: column;
    background-color: white;
    flex-grow: 1;
    flex-basis: 0%;
    margin-left: 20px;
    margin-bottom: 10px;
    border-radius: 20px;
    padding: 20px;
    align-items: stretch;
}

.transcriptScriptParentContainer {
    display: flex;
    flex: 1;
    margin-bottom: 10px;
    position: relative;
}

.transcriptScriptContainer {
    position: absolute;
    inset: 0;
    background-color: #f5f6fc;
    padding: 20px;
    border-radius: 10px;
    overflow-y: auto;
    clip-path: inset(0 0 0 0 round 10px);
}

.transcriptItemHeader {
    font-weight: 300;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 5px;
}

.transcriptItemContent {
    font-weight: 475;
    font-size: 12px;

}

.transcriptSearchBox {
    display: flex;
}

.transcriptSearchBoxInput {
    flex: 2;
    margin: 5px;
    padding: 3px;
    outline: none; 
}

.transcriptSearchBoxInput:focus {
    border-width: 1px;
}

.transcriptSearchBoxButton {
    color: white;
    background-color: black;
    flex: 1;
    margin: 5px;
    padding: 3px;
    border-radius: 5px;
    border: none;
}