
.callPageContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.contentCardSectionHeader {
    display: flex;
    justify-content: left;
    align-items: center;
    height: 40px;
    font-family: 'Clash Display', sans-serif;
    font-size: 24px;
    font-weight: 500;
}

.contentCardSectionName {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.contentCardSection {
    background-color: white;
    padding: 32px;
    border-radius: 32px;
    margin: 10px;
}

.contentCards {
    display: flex;
}

.contentCardIcon {
    width: 30px;
    height: 30px;
    padding-right: 15px;
    padding-bottom: 3px;
}

.contentcardSection {
    overflow-y: scroll;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.contentcardSectionTitle {
    font-size: 22px;
    padding: 20px;
    font-weight: 300;
}